<template>
  <div class="top" style="text-align: center">
    <h3 style="margin: 20px 0">{{ list.title }}</h3>
    <p v-html="list.content"></p>
  </div>
</template>

<script>
import { getNewAbout } from "../../../api/home";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getLIst();
  },
  methods: {
    getLIst() {
      let id = this.$route.query.id;
      getNewAbout(id).then((res) => {
        console.log(res);
        this.list = res.data.code;
        console.log(this.list);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  p {
    text-align: center;
  }
}
</style>
